import React from 'react'



const Landlords = () => {

return <div className="main" role="main">
  <h2>Landlords</h2>
  <div className="landlordContainer">
  <p className="justify">
    Fix It All has years of experience in managing
    property portfolios on behalf of landlords. 
<br></br>
<br></br>
    Whatever you need in terms of maintaining your
    property, Fix It All can provide you with it. 
  </p>
</div>
</div>

}

export default Landlords
