import React from 'react'
import bathroom from '../images/bathroom_2.jpg'

const Generalrepairs = () => {

  return <div className="generalRepairs" role="main">
    <div className="container">
    <h2>Design, Create or Repair</h2>
    <p className="justify">
      Our team have years of experience
      in designing and creating beautiful new kitchens 
      and bathrooms. 
    </p>
      <img className="repair" alt="bathroom" src={bathroom}></img>
      <p className="justify">
      If a repair or a "face-lift" is more your bag then
      we can do that too! Whatever the job, we bring our highly-skilled
      and professional tradesmen to the table in order to give you the 
      best results possible.
    </p>
    </div>
  </div>

}

export default Generalrepairs
