import React from "react"
import email from '../images/email.png'
import phone from '../images/phone.png'

const Contact = () => {

    return <div className="main" role="main" style={{borderBottom: "1px solid black"}}>
    <div className="row">
      <a href="mailto:info@fix-it-all-scotland.com" className="row" style={{textDecoration: "none", color: "black"}}>
        <img className="icon" alt="email" src={email}></img>
        <p>info@fix-it-all-scotland.com</p>
      </a>
      <a href="tel: +44 7886890813" className="row" style={{textDecoration: "none", color: "black"}}>
        <img className="icon" alt="phone" src={phone}></img>
        <p>07886890813</p>
      </a>
    </div>
    </div>

  }

  export default Contact
