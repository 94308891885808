import "./App.css";
import React from "react";
import About from "./components/About";
import Header from "./components/Header";
import Landlords from "./components/Landlords";
import Generalrepairs from "./components/Generalrepairs";
import Services from "./components/Services";
import Mobilenav from "./components/Mobilenav";
import Contact from "./components/Contact";

function App() {
  return (
    <>
      <Header />
      <Contact />
      <About />
      <Generalrepairs />
      <Landlords />
      <Services />
      <Contact />
    </>
  );
}

export default App;
